/* eslint-disable @typescript-eslint/no-explicit-any */

/** Методы работы с API. */
export const useApi = () =>{
    const defaultHeaders = {
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    /**
     * Возвращает абсолютный url.
     * @param url Относительный url.
     * @returns Абсолютный url.
     */
    const getSecurityAbsoluteUrl = (url: string): string => {
        const config = useRuntimeConfig();
        return config.public.securityApiBaseUrl + url;
    }

    /**
     * Выполняет GET запрос.
     * @param url URL.
     * @returns Ответ по запросу.
     */
    const get = async <TResponse>(url: string) : Promise<TResponse> => {
        return await request('GET', url);
    }

    /**
     * Выполняет POST запрос.
     * @param url URL.
     * @param body Данные.
     * @returns Ответ по запросу.
     */
    const post = async <TResponse>(url: string, body: any) : Promise<TResponse> => {
        return await request('POST', url, body);
    }

    /**
     * Выполняет PUT запрос.
     * @param url URL.
     * @param body Данные.
     * @returns Ответ по запросу.
     */
    const put = async <TResponse>(url: string, body: any) : Promise<TResponse> => {
        return await request('PUT', url, body);
    }

    /**
     * Выполняет DELETE запрос.
     * @param url URL.
     * @param body Данные.
     * @returns Ответ по запросу.
     */
    const deleteRequest = async <TResponse>(url: string, body: any = null) : Promise<TResponse> => {
        return await request('DELETE', url, body);
    }

    /**
     * Выполняет PATCH запрос.
     * @param url URL.
     * @param body Данные.
     * @returns Ответ по запросу.
     */
    const patch = async <TResponse>(url: string, body: any) : Promise<TResponse> => {
        return await request('PATCH', url, body);
    }

    const request = async <TResponse>(method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', url: string,
        body: any = null, headers: any = defaultHeaders) : Promise<TResponse> => {
        const options = {
            method: method,
            body: body,
            headers: headers,
        }
        const { $api } = useNuxtApp();

        try {
            return await $api(url, options);
        } catch (e) {
            // Повтор попытки в случае протухания токена.
            return await $api(url, options);
        }
    }

    return {
        getSecurityAbsoluteUrl,
        get,
        post,
        put,
        patch,
        deleteRequest
    }
}
