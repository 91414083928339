export const ROUTES = {
    index: {
        link: '/',
        name: 'Главная',
    },
    users: {
        link: '/users',
        name: 'Пользователи',
    },
    user: {
        link: (link: string | undefined) => {
            return `/users/${link}`;
        },
        name: (name: string) => {
            return `${name}`;
        },
    },
    partners: {
        link: '/partners',
        name: 'Партнеры',
    },
    partner: {
        link: (link: string | number | null) => {
            return `/partners/${link}`;
        },
        name: (name: string) => {
            return `${name}`;
        },
    },
    partner_links: {
        link: (link: string | number | null) => {
            return `/partners/${link}/links`;
        },
        name: 'Ссылки',
    },
    promocodes: {
        link: (link: string | number | null) => {
            return `/partners/${link}/promocodes`;
        },
        name: 'Промокоды',
    },
    certificates: {
        link: (link: string | number | null) => {
            return `/partners/${link}/certificates`;
        },
        name: 'Сертификаты',
    },
    transactions: {
        link: '/transactions',
        name: 'Транзакции',
    },
    login: {
        link: '/login',
        name: 'Авторизация',
    },
    email_confirmed: {
        link: '/email/confirmed',
        name: 'Email подтвержден',
    },
    categories: {
        link: '/categories',
        name: 'Категории',
    },
};