import { default as indexOcsRkjobiDMeta } from "/app/pages/categories/index.vue?macro=true";
import { default as confirmedRQogapHQrZMeta } from "/app/pages/email/confirmed.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as _91partnerId_93lUjyUgGzEeMeta } from "/app/pages/partners/[partnerId].vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as _91userId_93mOuoHyVCfrMeta } from "/app/pages/users/[userId].vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "categories",
    path: "/categories",
    meta: indexOcsRkjobiDMeta || {},
    component: () => import("/app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "email-confirmed",
    path: "/email/confirmed",
    component: () => import("/app/pages/email/confirmed.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-partnerId",
    path: "/partners/:partnerId()",
    meta: _91partnerId_93lUjyUgGzEeMeta || {},
    component: () => import("/app/pages/partners/[partnerId].vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    meta: indexLG3Wtz8U6fMeta || {},
    component: () => import("/app/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "users-userId",
    path: "/users/:userId()",
    meta: _91userId_93mOuoHyVCfrMeta || {},
    component: () => import("/app/pages/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexnq54o81oozMeta || {},
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]